import styles from "./footer.module.css"

function Footer(){
    return (
        <div className={styles.container}>
            © 2021 Bou-Yu Chen
        </div>
    )
}

export default Footer