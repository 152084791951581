import styles from "./experience.module.css";
import experienceData from "./experience_data";

function Experience() {
  const listItems = experienceData.map((experience, idx) => (
    <div className={styles.experience} key={idx}>
      <div className={styles.imageContainer}>
        <img
          key={idx}
          className={styles.image}
          src={experience.logo}
          alt={experience.logoAlt}
        ></img>
      </div>
      <div className={styles.details}>
        <div className={styles.location}>
          {experience.name}, {experience.location}
        </div>
        <div className={styles.title}> {experience.title} </div>
        {/* Responsibilities, nested elements */}
        {experience.responsibilities && (
          <div className={styles.responsibilities}>
            <ul>
              {experience.responsibilities.description && (
                <li>{experience.responsibilities.description}</li>
              )}
              {experience.responsibilities.sections.map((section, sectionIdx) => (
                <li key={sectionIdx}>
                  <div className={styles.category}>{section.category}</div>
                  <ul>
                    {section.responsibilities.map((item, itemIdx) => (
                      <li key={itemIdx}>{item}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Highlights, no nested elements */}
        {experience.highlights && (
          <div className={styles.highlights}>
            <ul>
              {experience.highlights &&
                experience.highlights.map((highlight, hlIdx) => <li key={hlIdx}>{highlight}</li>)}
            </ul>
          </div>
        )}
      </div>
    </div>
  ));

  return (
    <div className={styles.experienceSection}>
      <h2 id="experience" className={styles.header}>
        Experience
      </h2>
      {listItems}
    </div>
  );
}

export default Experience;
