import styles from "./education.module.css";

function Education() {
  const data = [
    {
      school: "San Jose State University",
      degree: "M.S. Software Engineering",
      years: "2017 - 2018",
      logo: "https://bouyuc.com/static/images/sjsu.webp",
      alt: "San Jose State University logo"
    },
    {
      school: "Pennsylvania State University",
      degree: "B.S. Energy Engineering",
      years: "2010 - 2014",
      logo: "https://bouyuc.com/static/images/psun.png",
      alt: "Penn State University logo"
    },
  ];
  return (
    <div>
      <h2 id="education" className={styles.header}>Education</h2>
      <div className={styles.container}>
        {data.map((experience, idx) => (
          <div className={styles.education} key={idx}>
            <div className={styles.imageContainer}>
              <img
                className={styles.image}
                alt={experience.alt}
                src={experience.logo}
              ></img>
            </div>
            <div className={styles.details}>
              <div className={styles.school}> {experience.school} </div>
              <div className={styles.degree}> {experience.degree} </div>
              <div className={styles.years}> {experience.years} </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Education;
