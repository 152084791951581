const experienceData = [
  {
    logo: "https://bouyuc.com/static/images/logicLogo.webp",
    logoAlt: "Logic solutions logo",
    name: "Logic Solutions",
    location: "Plymouth MI, USA",
    title: "Software Engineer",
    startDate: "2021-08",
    endDate: "Present",
    // highlights: [
    //   "Developed cross-platform mobile applications leveraging Angular and Nativescript",
    //   "Spearheaded the research and development efforts of an Apple Watch application for client"
    // ],
  },
  {
    logo: "https://bouyuc.com/static/images/lalinkoLogo.webp",
    logoAlt: "Lalinko Corporation logo",
    name: "Lalinko Corporation",
    location: "Santa Clara CA, USA",
    title: "Founding Software Engineer",
    startDate: "2019-06",
    endDate: "2021-04",
    responsibilities: {
      description:
        "Lalinko offers digital Contact Management with Big Data Analytics",
      sections: [
        {
          category: "Leadership",
          responsibilities: [
            "Led a team of software engineers and executed stages of the Software Development Life Cycle to actualize concepts into Lalinko’s core products",
            "Collaborated with the team to draft software specifications and to manage project timelines"
          ],
        },
        {
          category: "Cross platform mobile app development",
          responsibilities: [
            "Architected, developed and published Lalinko Free on both Android and iOS leveraging Ionic, Angular, Cordova, OCR",
            "Scrapped and parsed ~5000 web pages utilizing Python and leveraging beautiful soup library to provide big data analytics for users",
            "Enabled Lalinko Free to render 3000+ contacts smoothly by leveraging virtual scrolling and developing custom components",
            "Customized 3 Cordova plugins by modifying Android and iOS Native code"
          ],
        },
        {
          category: "Web application development",
          responsibilities: [
            "Architected and developed Lalinko Pro, a responsive web application that assists enterprise sales teams with the prospecting stage of the CRM pipeline, leveraging Angular and Firebase",
            "Developed core features allowing sales team admins to design, assign business cards and manage employee accounts, utilizing Fabric.js, AngularFire, and RxJS",
            "Spearheaded the full stack development of customer sign up and email verification system using Angular, Firebase Admin SDK, Nodejs, and Nodemailer",
          ],
        },
        {
          category: "Download Lalinko Free:",
          responsibilities: [
            <a href="https://apps.apple.com/us/app/lalinko-digital-business-card/id1494422795?ign-mpt=uo%3D2">
              <img src="https://bouyuc.com/static/images/download-app-apple.svg" alt="Download Lalinko for iOS"></img>
            </a>,
            <a href="https://play.google.com/store/apps/details?id=com.lalinko.app">
              <img src="https://bouyuc.com/static/images/download-app-android.svg" alt="Download Lalinko for Android"></img>
            </a>,
          ],
        },
      ],
    },
  },
  {
    logo: "https://bouyuc.com/static/images/lilee.webp",
    logoAlt: "Lilee Systems logo",
    name: "Lilee Systems",
    location: "Santa Jose CA, USA",
    title: "Software Engineer, Intern",
    startDate: "2018-07",
    endDate: "2018-12",
    highlights: [
      "Utilized Python to extract and analyze over one million data points from ROS bag files to benchmark the velocity, acceleration and trajectory of our self-driving bus to improve passenger comfort",
      "Developed an onboard dashboard displaying the lidar view, camera footage, and traffic signal of the trip using Python and Linux tools to educate and inform passengers on the self-driving bus status",
      "Reduced manual labor by 4 hours per day by automating self-driving bus data upload to AWS S3 by using shell script, systemd and AWS CLI",
      "Prototyped a vector map mapping tool for autonomous vehicles using ROS, RViz and C++"
    ],
  },
];

export default experienceData;
