import styles from "./projects.module.css";

function Projects() {
  let items = [
    {
      name: "Where in the world",
      img: "https://bouyuc.com/static/images/project-where.webp",
      alt: "Project 'Where in the world' screenshot",
      link: "https://bouyuc.com/where-in-the-world/",
      category: "Frontend",
    },
    {
      name: "Weather PWA",
      img: "https://bouyuc.com/static/images/weather.webp",
      alt: "PWA Weather app screenshot",
      link: "https://bouyuc.com/weather",
      category: "Frontend",
    },
    {
      name: "IoT Sous Vide machine",
      img: "https://bouyuc.com/static/images/sous.webp",
      alt: "Photo of my IoT Sous vide device",
      link: "https://docs.google.com/presentation/d/1aVzeanvqwrbNhLOOrA9yGe1l_gy3wenXlxk7vHFNIwA/edit?usp=sharing",
      category: "IoT",
    },
    {
      name: "IoT Curtains",
      img: "https://bouyuc.com/static/images/curtains.webp",
      alt: "Photo of my IoT curtains",
      link: "https://youtu.be/gTypyALeDu4",
      category: "IoT",
    },
    {
      name: "IoT Garage Door",
      img: "https://bouyuc.com/static/images/garage.webp",
      alt: "Photo of my IoT garage door",
      link: "https://youtu.be/y8sq3Dcri20",
      category: "IoT",
    },
  ];

  function navigateTo(link) {
    window.history.pushState({},"","#projects");
    window.location.assign(link);
  }

  return (
    <div>
      <h2 className={styles.header} id="projects">Projects</h2>
      <div className={styles.projectList}>
        {items.map((item, idx) => (
          <div
            key={idx}
            className={styles.projectItem}
            onClick={() => navigateTo(item.link)}
          >
            <div className={styles.imageContainer}>
              <img
                className={styles.projectImage}
                src={item.img}
                alt={item.alt}
              ></img>
            </div>
            <div className={styles.projectName}> {item.name} </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
