import "./App.css";
import Experience from "./components/experience";
import Education from "./components/education";
import Projects from "./components/projects";
import Footer from "./components/footer";
import BannerBg from "./images/1n2.webp";
import { FaGithub, FaLinkedin, FaFilePdf, FaArrowDown } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <div
        className="topBanner"
        style={{
          backgroundImage: `url(${BannerBg})`,
          zIndex: "1",
          minHeight: "100vh",
          margin: 0,
          padding: 0,
        }}
      >
        <div
          className="bannerCover"
          style={{
            position: "absolute",
            color: "white",
            textAlign: "center",
            width: "100%",
          }}
        >
          <div className="bannerText">
            <p className="nameTitle">Bou-Yu Chen</p>
            <p className="overview-section">
              Ambitious Software Engineer with 2 years hands-on experience of
              cross-platform mobile and web application development with
              eagerness to master full-stack development and software
              architecture.
              <br></br>
              End-to-end software development adept.
            </p>
            <div className="bannerActionButtons">
              <a
                href="https://www.github.com/bouyuc"
                rel="noreferrer"
                target="_blank"
              >
                <FaGithub className="react-icons"></FaGithub>
                <span>Github</span>
              </a>
              <a
                href="https://www.linkedin.com/in/bouyuc"
                rel="noreferrer"
                target="_blank"
              >
                <FaLinkedin className="react-icons"></FaLinkedin>
                <span>LinkedIn</span>
              </a>
              <a
                href="https://bouyuc.com/static/Bou-Yu_Chen_Resume.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <FaFilePdf className="react-icons"></FaFilePdf>
                <span>PDF Resume</span>
              </a>
            </div>
          </div>
        </div>
        <a className="learnMore bounce" href="#experience">
          <FaArrowDown></FaArrowDown>
        </a>
      </div>

      {/* Overview section */}
      {/* <h2>About me section</h2> */}

      <div className="infobody">
        {/* Experience */}
        <Experience></Experience>

        {/* Education */}
        <Education></Education>

        {/* Projects */}
        <Projects></Projects>
      </div>

      {/* Footer */}
      <Footer></Footer>
    </div>
  );
}

export default App;
